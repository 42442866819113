import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const manageSubscriptionService = service
  .enhanceEndpoints({ addTagTypes: ['ManageSubscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getManageSubscription: builder.query<
        Res['manageSubscription'],
        Req['getManageSubscription']
      >({
        providesTags: ['ManageSubscription'],
        query: (query: Req['getManageSubscription']) => ({
          url: `customers/companies/${query.id}/hosted-page`,
        }),
      }),
    }),
  })

export async function getManageSubscription(
  store: any,
  data: Req['getManageSubscription'],
  options?: Parameters<
    typeof manageSubscriptionService.endpoints.getManageSubscription.initiate
  >[1],
) {
  store.dispatch(
    manageSubscriptionService.endpoints.getManageSubscription.initiate(
      data,
      options,
    ),
  )
  return Promise.all(
    store.dispatch(manageSubscriptionService.util.getRunningQueriesThunk()),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetManageSubscriptionQuery,
  // END OF EXPORTS
} = manageSubscriptionService

/* Usage examples:
const { data, isLoading } = useGetManageSubscriptionQuery({ id: 2 }, {}) //get hook
const [createManageSubscription, { isLoading, data, isSuccess }] = useCreateManageSubscriptionMutation() //create hook
manageSubscriptionService.endpoints.getManageSubscription.select({id: 2})(store.getState()) //access data from any function
*/
