import { useGetCompaniesQuery } from './services/useCompany'
import { useGetUserQuery } from './services/useUser'
import sortBy from 'lodash/sortBy'
import { getApi } from './api'
export default function () {
  const token = getApi().getToken?.()
  const { data: user } = useGetUserQuery({}, { skip: token })

  const { data } = useGetCompaniesQuery({}, { skip: !user || token })
  const subscriptions = data?.flatMap((company) =>
    company.subscriptions.map((v) => {
      const subscription = company.subscriptions?.find(
        (companySubscription) => v.id === companySubscription.id,
      )
      const name = `${subscription?.company_name || company.name} - ${v.name}`
      const is_partner_customer = subscription?.chargebee_id === null
      return {
        ...v,
        chargebee_id: company.chargebee_id,
        company,
        company_id: company.id,
        company_name: company.name,
        display_name: `${name} ${v.is_cancelled ? ' (Cancelled)' : ''}`,
        is_partner_customer,
      }
    }),
  )

  return sortBy(subscriptions, (sub) => {
    return `${sub.is_cancelled ? 1 : 0}${sub.display_name}`
  })
}
