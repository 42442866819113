export let Project = {
  address: '86-90 Paul Street\nLondon, EC2A 4NE',
  api: 'https://api.hoxtonmix.com/api/v3/',

  auth: {
    cognito: true,
  },
  chargbeeSite: 'hoxtonmix',
  chargebeeUrl: 'https://checkout.hoxtonmix.com',
  codepush: {
    android: {
      production: 'ZgrOQgAz1Xtx1BdhcNCk5moU9_etIfPr8Ale8',
      staging: '_Y4F2rRWKOUIpaSXryXt5DwdoAzZwfQnbICNu',
    },
    ios: {
      production: '_eKRhxI_Zb6lizWQQwbCsGkdtJsbqr5M-Cn2P',
      staging: 'BpAa6k1Z_H8ABhUiCGBkLiZf9DRij5te80pSX',
    },
  },
  cognito: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_2rhDN9Aac',
    userPoolWebClientId: '4f68p84gqg362bpuvvh8dab6',
  },
  cognitoMobile: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_2rhDN9Aac',
    userPoolWebClientId: '4f68p84gqg362bpuvvh8dab6',
  },
  companyAPI: 'https://api.hoxtonmix.com/api/v3/',
  crisp: '708c694e-aa84-49e2-8cd3-0fb15d186d01',
  dataRelay: '1637-c53c-21bc',
  flagsmith: 'EuUX6f5pCYgCHDgK9vqf95',
  gtm: 'GTM-K636ZW',
  hoxtonmixUrl: 'https://hoxtonmix.com/',
  logs: {
    ANALYTICS: true,
    API: false,
    STORAGE: false,
  },
  loqate: 'PR28-BF96-PB24-GY46',
  mobileApp: 'hmportal',
  paypal:
    'ARLU1HFkZQXD_swGtgbR2tN7f-bFYoyYlPUL69PmwHFOioelEiSh-NxichfQuPCj05UoNAeUE0Ptz-xB',
  portalUrl: 'https://account.hoxtonmix.com',
  redirectSignIn: '/auth/account-verify/',
  sentry:
    'https://7c4558361cd248d3bcdc4469f4861b32@o31090.ingest.sentry.io/4505487325265920',
  sentryEnvironment: 'production',
  siteName: 'Hoxton Mix',
  stripe: 'pk_test_CvegxsaywY0KQ1iu62m08PQx',
  testCafeStage: 'staging',
  ...(typeof projectOverrides !== 'undefined' ? projectOverrides : {}),
}
