import React, { FC, FormEvent, useState } from 'react'
import { useCreateTopUpMutation } from 'common/services/useTopUp'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { ErrorMessage } from './Messages'
import Button from './base/forms/Button'
import Row from './base/grid/Row'
import { Format, Utils } from 'common/utils'
import { Mail, MailActionType } from 'common/types/responses'
import useActionTotal from 'common/hooks/useActionTotal'
import { useCreateVerifyTopupMutation } from 'common/services/useVerifyTopup'
import Checkbox from './base/forms/Checkbox'
import dayjs from 'dayjs'
import Tooltip from './Tooltip'
import { Project } from 'common/project'
import classNames from 'classnames'
import { Address } from 'common/types/requests'
import AddressSelect from './Address'
import InputGroup from './base/forms/InputGroup'
import { ModalTitle } from './ModalDefault'
import { getActionDisplayName } from 'common/services/useMail'
import Loader from './Loader'

type TopupType = {
  mail?: Mail[]
  isLoading?: boolean
  actionType?: MailActionType
  onComplete?: (
    mail: Mail[],
    actionType: MailActionType,
    data: any,
    bypassTopup: boolean,
  ) => void
  closeModal: () => void
}

let _cbInstance: any = null
const loadChargbee = (onLoad: () => void) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://js.chargebee.com/v2/chargebee.js'
  script.setAttribute('data-cb-site', Project.chargbeeSite)
  script.setAttribute('data-cb-domain', Project.chargebeeUrl)
  script.setAttribute('data-cb-gtm-enabled', 'true')
  script.setAttribute('data-cfasync', 'false')
  script.onload = onLoad
  document.head.appendChild(script)
}
if (typeof window !== 'undefined') {
  loadChargbee(() => {
    const cbInstance =
      _cbInstance ||
      Chargebee.init({
        // your test site
        domain: Project.chargebeeUrl,
        site: Project.chargbeeSite, // this is an optional parameter.
        // Use this, if custom domain is enabled for your site
      })

    _cbInstance = cbInstance
  })
}

const Topup: FC<TopupType> = ({
  actionType,
  closeModal,
  isLoading: saving,
  mail,
  onComplete,
}) => {
  const { defaultSubscriptionId, hasPermission, subscriptionDetail } =
    useDefaultSubscription()
  const companyId = subscriptionDetail?.company_id
  const [createTopup, { error: _error }] = useCreateTopUpMutation()
  const [createVerifyTopup, { error: _error2 }] = useCreateVerifyTopupMutation()
  const error = _error || _error2
  const canTopup = hasPermission('TOPUP')
  const [amount, setAmount] = useState<number>()
  const [address, setAddress] = useState<Address>({
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    country: '',
    postal_code: '',
    ...(subscriptionDetail?.address || {}),
  })
  const [draftAddress, setDraftAddress] = useState<Address>({
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    country: '',
    postal_code: '',
  })
  const { items } = useActionTotal(mail, actionType)
  const [selected, setSelected] = useState<Mail[]>(
    items?.map((v) => v.mail) || [],
  )
  const { total } = useActionTotal(selected, actionType)
  const [isLoading, setIsLoading] = useState(false)

  const needsAddress = actionType === 'FORWARD'
  const addressValid = Utils.validateAddress(address)
  const draftAddressValid = Utils.validateAddress(draftAddress)
  const [step, setStep] = useState(1)

  const toggleSelected = (v: Mail) => {
    if (selected.includes(v)) {
      setSelected(selected.filter((x) => v.id !== x.id))
    } else {
      setSelected(selected.concat([v]))
    }
  }
  const submit = (e: FormEvent) => {
    e?.preventDefault()
    if (isLoading) {
      return
    }
    if (companyId) {
      setIsLoading(true)
      createTopup({
        amount: amount!,
        id: companyId,
        redirect_url: document.location.origin,
      }).then((res) => {
        // @ts-ignore
        if (res.data?.url) {
          Chargebee.getInstance().openCheckout({
            close: function () {
              setIsLoading(false)
            },
            error: function () {
              setIsLoading(false)
            },
            hostedPage() {
              // @ts-ignore
              return Promise.resolve(res.data)
            },
            success: async (hostedPageId: string) => {
              _cbInstance?.closeAll?.()
              createVerifyTopup({
                hosted_page: hostedPageId,
                id: companyId,
              })
                .then((res) => {
                  // @ts-ignore
                  if (!res.error) {
                    onComplete?.(selected, actionType!, address, true)
                    closeModal()
                  }
                })
                .finally(() => {
                  setIsLoading(false)
                })
            },
          })
        } else {
          onComplete?.(selected, actionType!, address, true)
          closeModal()
        }
      })
    }
  }
  const content = ` <div>
          We will charge the default payment method against your account. Unspent credit will be kept on your account and can be used for future forwarding or scanning.
          <br/>  <br/>
          <p>
         
                      Please note that the prices stated do not include VAT. The total
            amount will be subject to VAT.
</p>
        </div>`

  const balance = subscriptionDetail?.company.account_balance || 0
  const hasEnoughCredit = !!actionType && balance >= total
  const hadEnoughCredit = !!actionType && balance >= total
  const creditNeeded = total - balance

  if (step === 2 || (needsAddress && !addressValid)) {
    return (
      <>
        <ModalTitle
          title={`${getActionDisplayName(actionType!)} - Enter Address`}
          onDismiss={closeModal}
        />
        <p>
          To forward post items to a new address, enter the full address below.
        </p>
        <AddressSelect
          onChange={setDraftAddress}
          name={'address'}
          title={'Address'}
          value={draftAddress}
        />
        <hr />
        <div className='text-right py-2'>
          <Button
            onClick={() => {
              setStep(1)
            }}
            disabled={needsAddress && !addressValid}
            className='btn-modal me-2'
            theme='darkOutline'
          >
            Go Back
          </Button>
          <Button
            className='btn-modal'
            type='button'
            onClick={() => {
              setAddress(draftAddress)
              setStep(1)
            }}
            disabled={!draftAddressValid}
          >
            Confirm
          </Button>
        </div>
      </>
    )
  }
  return (
    <>
      <ModalTitle
        title={getActionDisplayName(actionType!)}
        onDismiss={closeModal}
      />
      <form
        onClick={() => {
          document.body.click()
        }}
        className='topup-form d-flex flex-column mt-2 mx-6'
        onSubmit={submit}
      >
        <ErrorMessage>{error}</ErrorMessage>
        <div className='flex-fill'>
          {actionType ? (
            <>
              {!hadEnoughCredit && (
                <p>
                  <strong>Oops!</strong> Your account doesn’t have enough
                  credit. You’ll need to top-up before you can proceed.
                </p>
              )}

              <div className='subscription-table'>
                <table>
                  <thead>
                    <tr>
                      <th>Mail item</th>
                      <th className='text-right' width={100}>
                        Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map(({ mail: mailItem, price }) => {
                      return (
                        <tr key={mailItem.id}>
                          <td onClick={() => toggleSelected(mailItem)}>
                            <div className='flex-row cursor-default text-semibold align-items-center'>
                              {mail?.length !== 1 && (
                                <div className='me-5'>
                                  <Checkbox
                                    checked={selected.includes(mailItem)}
                                    id={mailItem.id}
                                  />
                                </div>
                              )}
                              {dayjs(mailItem.date).format('Do MMM YYYY HH:mm')}
                            </div>
                          </td>
                          <td className={'text-right'}>
                            {Format.money(price)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className='mt-2 text-right'>
                  <div className='me-5'>
                    Total:{' '}
                    <span className='text-primary text-bold'>
                      {Format.money(total)}
                    </span>
                  </div>
                  <div className='me-5'>
                    Account Credit:{' '}
                    <span
                      className={classNames('text-bold', {
                        'text-danger': !hasEnoughCredit,
                        'text-primary': hasEnoughCredit,
                      })}
                    >
                      {Format.money(balance)}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='mb-10'>
              <h3 className='mb-2'>
                Your account credit is{' '}
                <span className='text-primary text-bold'>
                  {Format.money(balance)}
                </span>
              </h3>
              Top up your account now to ensure you don’t miss out on any
              important post or parcels.
            </div>
          )}

          {needsAddress && (
            <>
              <InputGroup
                inputProps={{ readOnly: true }}
                value={Format.address(address)}
                touched={true}
                title={'Shipping Address'}
                isValid={!!addressValid}
              />
              <Button
                className={'px-0 mb-4'}
                onClick={() => setStep(2)}
                theme={'text'}
              >
                Use a different address
              </Button>
            </>
          )}
          {!hasEnoughCredit && (
            <>
              <h4>
                <Tooltip title='About Top-ups' tooltip={content}>
                  Top-up
                </Tooltip>
              </h4>
              {canTopup ? (
                <Row className='flex-wrap'>
                  {[500, 1000, 1500, 2000].map((v) => (
                    <Button
                      disabled={creditNeeded > v}
                      theme={amount === v ? 'secondary' : 'darkOutline'}
                      className='me-2 btn-money mb-2 px-0'
                      key={v}
                      onClick={() => setAmount(v)}
                    >
                      {Format.money(v)}
                    </Button>
                  ))}
                </Row>
              ) : (
                <div>
                  You do not have permissions to add funds to the account.
                </div>
              )}
            </>
          )}
          {isLoading && (
            <div className='text-center'>
              <Loader height='100px' width='100px' />
              <div>Processing payment</div>
            </div>
          )}
          {saving && (
            <div className='text-center'>
              <Loader height='100px' width='100px' />
              <div>Processing actions</div>
            </div>
          )}
        </div>

        <ErrorMessage>{error}</ErrorMessage>
        <hr />
        <div className='text-right py-2'>
          <Button
            onClick={closeModal}
            className='btn-modal me-2'
            theme='darkOutline'
          >
            Cancel
          </Button>
          {hasEnoughCredit ? (
            <Button
              disabled={(needsAddress && !addressValid) || saving || isLoading}
              onClick={() => {
                closeModal()
                onComplete?.(selected, actionType, address, true)
              }}
              className='btn-modal'
            >
              Continue
            </Button>
          ) : (
            <Button
              className='btn-modal'
              type='submit'
              disabled={
                isLoading ||
                !amount ||
                creditNeeded > amount ||
                !canTopup ||
                (needsAddress && !addressValid)
              }
            >
              Top up
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default Topup
