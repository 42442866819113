import { LocalizedStringsMethods } from './types/string-types'

export type LocalizedStrings = LocalizedStringsMethods

type StringsSingleton = {
  instance: LocalizedStrings | undefined
}

const strings: StringsSingleton = {
  instance: undefined,
}

const getStrings: () => LocalizedStrings = () => {
  if (!strings.instance) {
    throw new Error(
      'Strings is not initialized. Make sure Web or Mobile sets strings.instance',
    )
  }
  return strings.instance
}
export type LanguageContent = typeof defaultContent

const setStrings = (stringsParam: LocalizedStrings) => {
  strings.instance = stringsParam
}

export function blobbyStrings(strings: Record<string, string>) {
  // @ts-ignore
  const blobby: LanguageContent = {}
  Object.keys(strings).map((key) => {
    // @ts-ignore
    const val = strings[key]
    const words = val.split(' ')
    const newWordsBlobby = words.map((word: string, index: number) => {
      const arr = ['eeeee', 'blob', 'blobby', 'wuueeeeh']
      const idx = Math.floor(Math.random() * arr.length)
      if (word.indexOf('{') !== -1) {
        // reserve params
        return word
      }
      return arr[idx]
    })
  })
  return blobby
}

const defaultContent = {}
const stringRecords: Record<string, Partial<LanguageContent>> = {
  en: defaultContent,
  es: blobbyStrings(defaultContent),
}
export { stringRecords, getStrings, setStrings }
