import { PaymentSourceStatus, PaymentSourceType } from 'common/types/responses'

export const getPaymentTypeText = (type: PaymentSourceType) => {
  switch (type) {
    case 'card':
      return 'Card'
    case 'paypal_express_checkout':
      return 'Paypal'
    case 'direct_debit':
      return 'Direct Debit'
    case 'amazon_payments':
      return 'Amazon Pay'
    default:
      return 'Unknown'
  }
}

export const getPaymentStatusText = (status: PaymentSourceStatus) => {
  switch (status) {
    case 'valid':
      return 'Valid'
    case 'expiring':
      return 'Expiring'
    case 'expired':
      return 'Expired'
    case 'invalid':
      return 'Invalid'
    case 'pending_verification':
      return 'Pending Verification'
    default:
      return 'Unknown'
  }
}
