import { FC } from 'react'
import Icon from './Icon' // we need this to make JSX compile

type CustomSelectType = {
  label: string
}

const CustomSelect: FC<CustomSelectType> = ({ children, label }) => {
  return (
    <>
      <a
        className='nav-link w-100 flex-row align-items-center dropdown-toggle'
        href='#'
        role='button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <span className='mx-2 align-items-start flex-fill text-truncate'>
          <span>{label}</span>
        </span>
        <span className={'mx-3'}>
          <span className='fa fa-chevron-down' />
        </span>
      </a>
      <ul className='dropdown-menu shadow-1'>{children}</ul>
    </>
  )
}

export default CustomSelect
