import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { FC } from 'react'
import useSubscriptions from 'common/useSubscriptions'
import { useGetUserQuery, useLogoutMutation } from 'common/services/useUser'
import Icon from './Icon'
import CustomSelect from './CustomSelect'
import { useRouter } from 'next/router'

type SubscriptionSelectType = {}

const SubscriptionSelect: FC<SubscriptionSelectType> = ({}) => {
  const { setDefaultSubscriptionId, subscriptionDetail } =
    useDefaultSubscription()
  const router = useRouter()
  const subscriptions = useSubscriptions()
  const { data: user } = useGetUserQuery({})
  const [logout] = useLogoutMutation()
  const handleSelect = (id: string) => {
    if (router.query.subscriptionId) {
      router.replace('/')
    }
    setDefaultSubscriptionId(id)
    // API.loginRedirect(user, id)
  }

  if (!subscriptions || subscriptions?.length <= 1) {
    return (
      <div className={'d-flex flex-column'}>
        <div className='text-white text-bold'>
          {subscriptions?.[0]?.display_name}
        </div>
        <div className='text-white'>
          {user?.username} ({subscriptions?.[0]?.name})
        </div>
      </div>
    )
  }

  return (
    <div className='nav-item w-100 dropdown'>
      <CustomSelect label={`${subscriptionDetail?.display_name}`}>
        {subscriptions?.map((subscription) => (
          <li key={subscription.id} className='dropdown-item'>
            <div
              onClick={() => handleSelect(subscription.id as any)}
              className='flex-row align-items-center'
              key={subscription.id}
            >
              <div className='d-flex me-2 flex-fill'>
                <span className='text-nowrap'>{subscription.display_name}</span>
              </div>
              <span className='fa-chevron-right' />
            </div>
          </li>
        ))}
      </CustomSelect>
    </div>
  )
}

export default SubscriptionSelect
