import 'project/polyfill'
import { AppProps } from 'next/app'
import 'styles/Global.scss'
import LanguageHandler from 'components/LanguageHandler'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import NProgress from 'components/util/NProgress'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { FC, ReactElement, ReactNode, useEffect } from 'react'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import { ToastContainer } from 'components/Toast'
import ProfileContainer from 'components/ProfileContainer'
import { Project } from 'common/project'
import { PersistGate } from 'redux-persist/integration/react'
import { getPersistor } from 'common/store'
import cognito from 'common/cognito'
import Nav from 'components/nav/Nav'
import Sidebar from 'components/sidebar/Sidebar'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}
import AnalyticsContainer from 'components/AnalyticsContainer'
import CrispProvider from 'common/CrispProvider'
import FeatureFlagProvider from 'components/FeatureFlagProvider'

type ComponentType = AppPropsWithLayout

cognito.init(Project.cognito)

export const defaultLayout = (page: ReactElement) => (
  <div className='d-flex flex-column flex-md-row flex-row'>
    <Sidebar />
    <div className='flex-grow-1'>
      <Nav />
      {page}
    </div>
  </div>
)
const AppComponent: FC<ComponentType> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || defaultLayout
  const router = useRouter()

  useEffect(() => {
    require('bootstrap/dist/js/bootstrap.bundle.min.js')
  }, [])

  if (router.asPath.includes('sw.js')) {
    return null
  }
  return (
    <PersistGate persistor={getPersistor()}>
      <LanguageHandler>
        <AnalyticsContainer Component={Component} />
        <Head>
          <meta charSet='utf-8' />
          <meta httpEquiv='x-ua-compatible' content='ie=edge' />
          <meta name='description' content='HM Portal' />
          <meta name='theme-color' content='#317EFB' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, shrink-to-fit=no'
          />
          <meta name='theme-color' content='#ffffff' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
          />
          <meta property='fb:admins' content='453323778023845' />
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/apple-touch-icon.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32x32.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16x16.png'
          />
          <link rel='manifest' href='/site.webmanifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#42185a' />
          <meta name='msapplication-TileColor' content='#ffffff' />
          <meta name='theme-color' content='#ffffff' />
          <link rel='manifest' href='/manifest.webmanifest' />
          <meta property='og:title' content={Project.siteName} />
        </Head>
        <NProgress />
        <ProfileContainer>
          {(user: any) => (
            <FeatureFlagProvider>
              {getLayout(<Component {...pageProps} />, pageProps, user)}
            </FeatureFlagProvider>
          )}
        </ProfileContainer>
        <CrispProvider />
        <ToastContainer />
        <div id='modal' />
        <div id='confirm' />
        <div id='alert' />
      </LanguageHandler>
    </PersistGate>
  )
}

export default nextReduxWrapper.withRedux(AppComponent)
