// @ts-ignore
import { Permission } from 'common/types/responses'

const DEV = global.__DEV__
  ? // @ts-ignore
    global.__DEV__
  : // @ts-ignore
  typeof __DEV__ === 'undefined'
  ? false
  : // @ts-ignore
    __DEV__

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  REQUEST_TIMEOUT: 10000,
  VAT: 0.2,
  defaultLocale: 'en-gb',
  enableSentry: !__DEV__,
  events: {},
  simulate: !__DEV__
    ? {}
    : {
        SKIP_KYC: true,
      },
}
