import { FC } from 'react'
import { useGetUserQuery } from 'common/services/useUser'
import { useHydrated } from 'common/services/useHydrated'
import { getApi } from 'common/api'

type ProfileContainerType = {}

const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data } = useGetUserQuery({}, { skip: getApi().getToken?.() })
  const hydrated = useHydrated()

  if (!hydrated) return null
  return <>{typeof children === 'function' ? children(data) : children}</>
}

export default ProfileContainer
