import { FC } from 'react'
import classNames from 'classnames' // we need this to make JSX compile

type UnreadType = {
  className?: string
}

const Unread: FC<UnreadType> = ({ children, className }) => {
  return (
    <span className={classNames('unread text-white bg-pink', className)}>
      {children}
    </span>
  )
}

export default Unread
