import { NextRouter } from 'next/router'
import { TPlanSummary } from 'common/cms-types'
import { Address } from 'common/types/requests'
//@ts-ignore
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i

export const omit = (obj: any, keys: string[]) => {
  const output = []
  for (const [key, value] of Object.entries(obj)) {
    if (!keys.includes(key)) {
      output.push([key, value])
    }
  }
  return Object.fromEntries(output)
}
export const Utils = {
  GUID: (append = '') => {
    let d = new Date().getTime()
    const uuid = 'xxxx-xxxx-xxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16)
      // eslint-disable-next-line
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    })

    return append ? `${uuid}-${append}` : uuid
  },
  fromParam(str?: string): Record<string, any> {
    // {min:100,max:200} <- ?min=100&max=200
    const documentSearch =
      typeof document === 'undefined' ? '' : document.location.search

    if (!str && !documentSearch) {
      return {}
    }
    // eslint-disable-next-line
    const urlString = (str || documentSearch).replace(/(^\?)/, '');
    return JSON.parse(
      `{"${urlString.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key, value) => (key === '' ? value : decodeURIComponent(value)),
    )
  },

  getClientContext: (router: NextRouter) => {
    //todo : move to /project
    return {
      defaultLocale: router.defaultLocale,
      locale: router.locale,
      locales: router.locales,
    }
  },

  getLocaleID: (locale: string) => {
    let matchingLocale = locale
    if (!locale.includes('-')) {
      switch (locale) {
        case 'en': {
          matchingLocale = 'gb'
          break
        }
        default: {
        }
      }
      return `${locale}-${matchingLocale}`
    }
    return locale
  },
  getMonthsFree: (plan: TPlanSummary) => {
    const yearlyMonthCost = plan.pricing.month_price * 12
    return (
      (yearlyMonthCost - plan.pricing.year_price) / plan.pricing.month_price
    )
  },
  isValidEmail(text: string) {
    // returns bool
    return emailRegex.test(text)
  },

  openChat() {
    if (typeof window.$crisp !== 'undefined') {
      window.$crisp.push(['do', 'chat:open'])
    }
  },
  toParam(obj?: Record<string, any>): string {
    // {min:100,max:200} -> min=100&max=200
    if (!obj) return ''
    const allDefined = omit(
      obj,
      Object.keys(obj).filter(
        (key) => typeof obj[key] === 'undefined' || obj[key] === null,
      ),
    )
    return Object.keys(allDefined)
      .map((k) => {
        // @ts-ignore
        let val = allDefined[k]
        if (Array.isArray('array')) {
          val = val.join(',')
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(val)}`
      })
      .join('&')
  },
  validateAddress: (address: Address | null) => {
    return (
      !!address?.address_line_1 &&
      !!address?.postal_code &&
      !!address?.country &&
      !!address?.city
    )
  },
}
