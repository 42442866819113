import React, { FC } from 'react'
import TopupSelect from 'components/TopupSelect'
import PaymentWarning from 'components/cards/PaymentWarning'
import SubscriptionSelect from 'components/SubscriptionSelect'
import { usePaymentWarning } from 'common/hooks/usePaymentWarning'
import PreviewBar from 'components/PreviewBar'
import usePreview from 'project/usePreview'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { kycNeedsVerifying } from 'common/services/useKycStatus'
import Icon from 'components/Icon'
import Link from 'next/link'
import { useFlags } from 'flagsmith/react'

type ComponentType = {
  extraLinks?: React.ReactNode
}

const Nav: FC<ComponentType> = ({ extraLinks }) => {
  const { paymentWarning, status } = usePaymentWarning()
  const { preview } = usePreview()
  const { subscriptionDetail } = useDefaultSubscription()
  const kyc = subscriptionDetail?.kyc_status
  const needsVerify =
    kycNeedsVerifying(subscriptionDetail) &&
    !subscriptionDetail?.is_partner_customer

  const { feature_topups } = useFlags(['feature_topups'])
  return (
    <>
      <nav className='navbar bg-secondary navbar-expand-md nav-root'>
        <div
          className=' mx-4 d-flex w-100 flex-row justify-content-between align-items-center'
          id='navbarCollapse'
        >
          <SubscriptionSelect />
          {feature_topups.enabled && (
            <div className='d-flex flex-row align-items-center gap-2'>
              <h4 className='m-0 d-none d-sm-block text-white'>
                Current Balance
              </h4>
              <TopupSelect />
            </div>
          )}
        </div>
      </nav>
      <PreviewBar />
      {paymentWarning && !needsVerify && (
        <div className={'text-white bg-dark-1 py-3 px-4'}>
          <PaymentWarning theme='long' />
        </div>
      )}
      {!!needsVerify && (
        <div className={'text-white bg-danger-pink py-3 px-4'}>
          <div className='flex-row me-10 align-items-center'>
            <div className='flex-fill text-sm'>
              <div className='flex-row align-items-center'>
                <Icon fill='white' name={'warning'} />
                <h1 className='text-semibold mb-0 ms-2'>
                  Your account is not verified
                </h1>
              </div>
              <div>
                We cannot process any mail for your account until you have
                verified your identity and proof of address
              </div>
            </div>
            {kyc?.kyc_link ? (
              <a
                href={kyc?.kyc_link}
                target='_blank'
                className='btn px-10 btn-outline-light'
                rel='noreferrer'
              >
                <span>Verify</span>
              </a>
            ) : (
              <Link
                href={'/business/owners'}
                className='btn px-10 btn-outline-light'
                rel='noreferrer'
              >
                Verify
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Nav
