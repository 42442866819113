import { FC, useEffect, useState } from 'react'
import InputGroup from './base/forms/InputGroup'
import { Address as AddressType } from 'common/types/requests'

import Input from './base/forms/Input'
import { Project } from 'common/project'
import { countriesArray } from 'common/countries'
import ReactSelect from 'react-select'

import DynamicAddressSearch from 'react-loqate'
import Button from './base/forms/Button'
import { safeParseEventValue } from 'common/utils/safeParseEventValue'
import { addressStrings } from 'common/strings/address-strings'
type AddressSelectType = {
  onBlur?: (e: FocusEvent) => void
  onChange: (v: AddressType) => void
  name: string
  errors?: any
  touched?: boolean
  title: string
  value: AddressType
}
const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#ced4da' : '#ced4da',
    borderRadius: 6,
    minHeight: 42,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    svg: {
      fill: state.isFocused ? '#000000' : '#000000',
    },
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#939393' : '#939393',
    marginBottom: 0,
    marginTop: 0,
    width: '0.5px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    letterSpacing: '-0.35px',
  }),
}

const AddressSelect: FC<AddressSelectType> = (props) => {
  const { errors, name, onChange, title, touched, value } = props
  const [manual, setManual] = useState(false)
  const setFieldValue = (name: keyof typeof props.value, value: any) => {
    onChange({
      ...props.value,
      [name]: value,
    })
  }

  useEffect(() => {
    if (name === 'billing_address') {
      if (props.value.address_line_1) {
        setManual(true)
      } else {
        setManual(false)
      }
      return
    } else if (props.value.address_line_1) {
      setManual(true)
    }
    //eslint-disable-next-line
  }, [props.value])

  const prefix = name ? `${name}.` : ''

  return (
    <div className='position-relative d-flex flex-column gap-2'>
      {manual && (
        <div className='d-flex flex-column gap-4' id={name}>
          <div className='d-flex justify-content-between gap-2 flex-nowrap'>
            <InputGroup
              className='flex-1'
              title='Address line 1'
              required
              name={`${prefix}address_line_1`}
              id={`${prefix}address_line_1`}
              onChange={(e) => {
                setFieldValue('address_line_1', safeParseEventValue(e))
              }}
              value={value.address_line_1}
              isValid={!errors?.address_line_1}
              touched={touched}
            />
            <InputGroup
              className='flex-1'
              title='Address line 2'
              name={`${prefix}address_line_2`}
              id={`${prefix}address_line_2`}
              onChange={(e) => {
                setFieldValue('address_line_2', safeParseEventValue(e))
              }}
              value={value.address_line_2 || ''}
              isValid={!errors?.address_line_2}
              touched={touched}
            />
          </div>
          <div className='d-flex justify-content-between gap-4 flex-nowrap'>
            <InputGroup
              className='flex-1'
              required
              title='City'
              name={`${prefix}city`}
              id={`${prefix}city`}
              onChange={(e) => {
                setFieldValue('city', safeParseEventValue(e))
              }}
              value={value.city}
              isValid={!errors?.city}
              touched={touched}
            />
            <InputGroup
              className='flex-1'
              required
              title={addressStrings.country}
              name={`${prefix}country`}
              id={`${prefix}country`}
              component={
                <ReactSelect
                  classNames={{
                    control: (state) =>
                      state.isFocused
                        ? 'react-select react-select--focused'
                        : 'react-select',
                  }}
                  classNamePrefix='react-select'
                  id='country'
                  name='country'
                  onChange={(v) => {
                    setFieldValue('country', v?.value)
                  }}
                  value={countriesArray.find((b) => b.value === value.country)}
                  options={countriesArray.sort(({ label: a }, { label: b }) =>
                    a.localeCompare(b),
                  )}
                  styles={selectStyles}
                />
              }
            />
            <InputGroup
              title='Postcode'
              required
              inputProps={{ maxLength: 20 }}
              name={`${prefix}postal_code`}
              id={`${prefix}postal_code`}
              onChange={(e) => {
                setFieldValue('postal_code', safeParseEventValue(e))
              }}
              value={value.postal_code}
              isValid={!errors?.postal_code}
              touched={touched}
            />
          </div>
        </div>
      )}
      {!manual && (
        <InputGroup
          className='position-relative'
          title={title}
          required
          id={name}
          component={
            <DynamicAddressSearch
              locale='en-GB'
              apiKey={Project.loqate}
              components={{
                Input: (props) => (
                  <Input
                    autoComplete='chrome-off'
                    {...props}
                    id={name}
                    touched={touched}
                    isValid={!errors}
                    placeholder='Start typing your address to search...'
                  />
                ),
              }}
              classes={{ list: 'styled-list' }}
              onSelect={(address) => {
                if (!address) {
                  return
                }
                const company = address.Company || address.BuildingName
                onChange({
                  address_line_1: company ? company : address.Line1,
                  address_line_2: company ? address.Line1 : address.Line2,
                  city: address.City,
                  country: address.CountryIso2,
                  postal_code: address.PostalCode,
                })
                setManual(true)
              }}
              inline
              debounce={100}
            />
          }
        />
      )}
      <div className='text-left'>
        {manual ? (
          <Button
            className='text-underline p-0 h-auto lh-auto'
            size={'small'}
            onClick={() => setManual(false)}
            theme='text'
          >
            Search for your address
          </Button>
        ) : (
          <Button
            className='text-underline p-0 h-auto lh-auto'
            size={'small'}
            onClick={() => setManual(true)}
            theme='text'
          >
            Enter address manually
          </Button>
        )}
      </div>
    </div>
  )
}

export default AddressSelect
