import { Mail, MailActionType, MailStatus, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import dayjs from 'dayjs'
import { Format, Utils } from 'common/utils'
import transformPaginatedResponse from './util/transformPaginatedResponse'

export const mailService = service
  .enhanceEndpoints({ addTagTypes: ['Mail'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMail: builder.query<Res['mail'], Req['getMail']>({
        providesTags: (res, _, req) => [
          { id: `LIST-${req.subscription_id}`, type: 'Mail' },
        ],
        query: (q) => ({
          url: `/mailbox/mails?${Utils.toParam(q)}`,
        }),
        transformResponse: (res, _req, query) => {
          return transformPaginatedResponse(
            {
              ...res,
              results: res.results.map((v: Mail) => {
                return {
                  ...v,
                  actions: v.read
                    ? v.actions.concat([
                        // Add a faux action letting users mark as read
                        {
                          action_type: 'MARK_AS_UNREAD',
                          price: 0,
                          requires_topup: false,
                        },
                      ])
                    : v.actions.concat([
                        // Add a faux action letting users mark as read
                        {
                          action_type: 'MARK_AS_READ',
                          price: 0,
                          requires_topup: false,
                        },
                      ]),
                  display_status: parseMailStatus(
                    v?.status,
                    v?.expiry,
                    v?.address || '',
                  ),
                  expired: new Date().valueOf() > new Date(v.expiry).valueOf(),
                  expiring: dayjs(v.expiry).diff(new Date(), 'days') < 7,
                }
              }) as Mail[],
            },
            query,
          )
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export function parseMailStatus(
  status: MailStatus | undefined,
  expiry: string,
  address: string,
) {
  const prettyStatus = status?.split(':')?.[0]?.replace('.', '')
  let actualStatus: MailStatus | undefined = status
  switch (prettyStatus) {
    case 'Mail Ready': {
      actualStatus = 'CREATED'
      break
    }
    case 'Forwarding Requested': {
      actualStatus = 'REQUESTED_FORWARD'
      break
    }
    case 'Scan and Forward Complete': {
      actualStatus = 'FORWARDED'
      break
    }
    case 'Forwarding Complete': {
      actualStatus = 'FORWARDED'
      break
    }
    case 'Post Scanned':
    case 'Scan Complete': {
      actualStatus = 'SCANNED'
      break
    }
    case 'Mail collected': {
      actualStatus = 'COLLECTED'
      break
    }
    default: {
      actualStatus = status
      break
    }
  }

  switch (actualStatus) {
    case 'ACCOUNT_FROZEN':
      return 'Your account has been frozen, please contact us to regain access.'
    case 'COLLECTED':
      return 'Your mail has been collected.'
    case 'CREATED': {
      return `Mail items will be stored at no cost for 30 days. If you require a
              physical copy, please collect or request a Forward before ${dayjs(
                expiry,
              ).format('DD MMMM YYYY')}`
    }
    case 'DESTROYED':
      return 'As per the storage policy in our Terms and Conditions, this mail item was destroyed after 30 days'
    case 'FORWARDED': {
      const formattedAddress = address
        .split(/[\n\r]/g)
        .filter((v) => !!v)
        .map((v) => v.replace(',', ''))
        .join(', ')
      return `We have forwarded this mail item to ${formattedAddress}.`
    }
    case 'ON_HOLD_INVOICE':
      return 'Your mail is being held whilst you update your payment method.'
    case 'ON_HOLD_KYC_ID':
      return 'Your mail is being held whilst you verify your identity.'
    case 'PENDING':
    case 'PROCESSING':
      return 'Your mail is being processed.'
    case 'REFUSED':
      return 'This mail item has been refused by our agents.'
    case 'REJECTED':
      return 'This mail item has been rejected by our agents.'
    case 'REQUESTED_FORWARD':
    case 'REQUESTED_SCAN':
      return 'We are processing your request.'
    case 'RETURNED':
      return 'This mail item is not covered in your plan and has been returned to the sender.'
    case 'SCANNED':
      return 'Your mail is ready for download.'
    default:
      return `${Format.enumeration.get(prettyStatus)}`
  }
}

export function formatStatus(status: MailStatus | string | undefined) {
  switch (status) {
    case 'CREATED': {
      return `Ready`
    }
    case 'ON_HOLD_INVOICE':
      return 'Awaiting payment'
    case 'ON_HOLD_KYC_ID':
      return 'Awaiting identity verification'
    default:
      return `${Format.enumeration.get(status?.split?.(':')?.[0])}`
  }
}

export async function getMail(
  store: any,
  data: Req['getMail'],
  options?: Parameters<typeof mailService.endpoints.getMail.initiate>[1],
) {
  return store.dispatch(mailService.endpoints.getMail.initiate(data, options))
}
// END OF FUNCTION_EXPORTS

export const {
  useGetMailQuery,
  // END OF EXPORTS
} = mailService

// const { data, isLoading } = useGetMailQuery({ id: 2 }, {}) get hook
// const [createMail, { isLoading, data, isSuccess }] = useCreateMailMutation() create hook
// mailService.endpoints.getMail.select({id: 2})(store.getState()) access data from any function

export const getActionDisplayName = (mailAction: MailActionType) => {
  switch (mailAction) {
    case 'SCAN':
      return 'Scan Mail'
    case 'DOWNLOAD':
      return 'Download Mail'
    case 'FORWARD':
      return 'Forward Mail'
    default:
      ''
  }
}
