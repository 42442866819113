import { FC, useEffect, useState } from 'react'
import { FlagsmithProvider } from 'flagsmith/react'
import flagsmith from 'flagsmith/isomorphic'
import { Project } from 'common/project'
import { useUser } from 'common/services/useUser'
import Loader from './Loader'

type FeatureFlagProviderType = {}

const FeatureFlagProvider: FC<FeatureFlagProviderType> = ({ children }) => {
  const { data } = useUser({})
  const [ready, setReady] = useState(flagsmith.loadingState?.source !== 'NONE')

  useEffect(() => {
    if (flagsmith.identity !== data?.username) {
      if (data?.username) {
        flagsmith.identify(data?.username, { email: data.username })
      } else {
        flagsmith.logout()
      }
    }
  }, [data])
  return (
    <FlagsmithProvider
      options={{
        cacheFlags: true,
        environmentID: Project.flagsmith,
        identity: data?.username,
        onChange: () => {
          if (flagsmith.loadingState?.source !== 'NONE' && !ready) {
            setReady(true)
          }
        },
        traits: data ? { email: data.username } : undefined,
      }}
      flagsmith={flagsmith}
    >
      {!ready ? (
        <div className='text-center'>
          <Loader />
        </div>
      ) : (
        <>{children}</>
      )}
    </FlagsmithProvider>
  )
}

export default FeatureFlagProvider
