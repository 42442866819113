import { FC } from 'react'
import { Project } from 'common/project' // we need this to make JSX compile

type WebsiteFooterType = {}

const WebsiteFooter: FC<WebsiteFooterType> = ({}) => {
  return (
    <div className='py-4 bg-light-grey'>
      <div className='container-fluid'>
        <div className='row'>
          {links.map((item, i) => (
            <div className='col-xl-2 col-md-4 col-12'>
              <h3>{item.title}</h3>
              {item.links.map((v) => (
                <a
                  className='text-body mb-2 d-block text-decoration-none'
                  target='_blank'
                  href={`https://www.hoxtonmix.com${v.href}`}
                  rel='noreferrer'
                >
                  {v.title}
                </a>
              ))}
              {i + 1 === links.length && (
                <div className='mt-4'>
                  <h3>Need Help?</h3>
                  <span>Monday - Friday 9am - 18.00pm</span>
                  <div className='mt-4'>
                    <a href='tel:+44 (0)20 347 53374'>+44 (0)20 347 53374</a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <ul className='list-unstyled d-flex flex-wrap justify-content-center gap-8 mt-5 rating-logos'>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='LW Rating Icon'
              width={72}
              height={57}
              src={'https://hoxtonmix.com/images/ratings/cyber-essentials.png'}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='LW Rating Icon'
              width={72}
              height={57}
              src={'https://hoxtonmix.com/images/ratings/lw-logo.svg'}
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='Google Rating Icon'
              width={84}
              height={55}
              src={
                'https://hoxtonmix.com/images/ratings/homepage-google-footer-icon-desktop.svg'
              }
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='ICO Rating Icon'
              width={94}
              height={55}
              src={
                'https://hoxtonmix.com/images/ratings/homepage-ico-footer-icon-desktop.svg'
              }
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid'
              alt='SSL Icon'
              width={101}
              height={62}
              src={
                'https://hoxtonmix.com/images/ratings/homepage-cloudflare-footer-icon-desktop.svg'
              }
            />
          </li>
          <li>
            <img
              loading='lazy'
              className='img-fluid fit-content'
              alt='Trusted Reviews Icon'
              width={182}
              height={56}
              src={
                'https://hoxtonmix.com/images/ratings/trusted_reviews_icon.svg'
              }
            />
          </li>
        </ul>
      </div>
      <div className='d-lg-flex d-none justify-content-center gap-4'>
        Copyright © 2024 {Project.siteName} Limited. All rights reserved.
        <div>Company no. 07212205</div>|<div>MLR no. 12883542</div>|
        <div>ICO no. ZA041914</div>
      </div>
    </div>
  )
}

export default WebsiteFooter

export function officeLinks() {
  return [
    {
      href: '/membership',
      title: `Membership`,
    },
    {
      href: '/paul-street-office',
      title: `Paul Street`,
    },
    {
      href: '/private-office-suites',
      title: `Private Offices`,
    },
  ]
}

export function serviceLinks() {
  return [
    {
      href: '/company-formation',
      title: `Company Formation`,
    },
    {
      href: '/meeting-rooms',
      title: `Meeting Rooms`,
    },
    {
      href: '/free-business-banking',
      title: `Free Business Banking`,
    },
  ]
}

export function enterpriseSystemLinks() {
  return [
    {
      href: '/konnect',
      title: `Konnect`,
    },
    {
      href: '/mailroom',
      title: `Mailroom`,
    },
  ]
}
export enum PlanSlug {
  COLLECT = 'virtual-office-collect',
  FORWARD = 'virtual-office-forward',
  INTERNATIONAL = 'virtual-office-international',
  MEETINGS = 'meeting-rooms',
  OFFICIAL_MAIL = 'official-government-mail',
  PHONE = 'virtual-office-phone',
  SCAN_LITE = 'virtual-office-scan-lite',
  SCAN_PRO = 'virtual-office-scan-pro',
}

export function productLinks(addAllProducts?: boolean) {
  const links = [
    {
      href: `/${PlanSlug.COLLECT}`,
      title: `Virtual Office Collect`,
    },
    {
      href: `/${PlanSlug.FORWARD}`,
      title: `Virtual Office Forward`,
    },
    {
      href: `/${PlanSlug.SCAN_LITE}`,
      title: `Virtual Office Scan Lite`,
    },
    {
      href: `/${PlanSlug.SCAN_PRO}`,
      title: `Virtual Office Scan Pro`,
    },
    {
      href: `/${PlanSlug.PHONE}`,
      title: `Virtual Office Phone`,
    },
  ]
  if (addAllProducts) {
    links.unshift({
      href: '/virtual-office-london-old-street',
      title: `${Strings.allVirtualOfficePlans}`,
    })
  }
  return links
}

export function customerCareLinks() {
  return [
    {
      href: 'https://help.hoxtonmix.com/en/',
      title: `Help & Advice`,
    },
    {
      href: '/contact-us',
      title: `Contact Us`,
    },
  ]
}

export function legalLinks() {
  return [
    {
      href: '/privacy-policy',
      title: `Privacy Policy`,
    },
    {
      href: '/terms-and-conditions',
      title: `Terms & Conditions`,
    },
    {
      href: '/know-your-customer',
      title: `KYC Requirements`,
    },
  ]
}

export function companyLinks() {
  return [
    {
      href: '/blog',
      title: `Blog`,
    },
    {
      href: '/about-us',
      title: `About Us`,
    },
    {
      href: '/social-impact',
      title: `Social Impact`,
    },
    {
      href: '/affordable-workspace',
      title: `Affordable Workspace`,
    },
    {
      href: '/business-support',
      title: `Business Support`,
    },
    {
      href: '/silicon-roundabout',
      title: `Silicon Roundabout`,
    },
    {
      href: '/outreach',
      title: `Outreach`,
    },
    {
      href: 'https://thehoxtonmix.statuspage.io/',
      title: `Uptime and Status`,
    },
    {
      href: '/mobile',
      title: `Mobile Apps`,
    },
  ]
}

const links = [
  {
    links: productLinks(),
    title: 'Products',
  },
  {
    links: enterpriseSystemLinks(),
    title: 'Partners',
  },
  {
    links: officeLinks(),
    title: 'Office Space',
  },
  {
    links: companyLinks(),
    title: 'Company',
  },
  {
    links: customerCareLinks(),
    title: 'Customer Care',
  },
  {
    links: legalLinks(),
    title: 'Legal Information',
  },
]
