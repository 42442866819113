import React, { FC, ReactNode, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { Utils } from 'common/utils' // we need this to make JSX compile

type TooltipType = {
  children?: ReactNode
  title?: string
  hideInfo?: boolean
  tooltip: string
  place?: 'top' | 'right' | 'bottom' | 'left'
}

const Tooltip: FC<TooltipType> = ({
  children,
  hideInfo,
  place,
  title,
  tooltip,
}) => {
  const id = useRef(Utils.GUID())
  return (
    <span className='question-tooltip'>
      <span data-for={id.current} data-tip>
        <div className='d-flex gap-4 align-items-center'>
          {children}
          {!hideInfo && (
            <svg
              width='19'
              height='18'
              viewBox='0 0 19 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.75 0.427734C16.4531 0.453776 17.0391 0.701172 17.5078 1.16992C17.9766 1.63867 18.224 2.22461 18.25 2.92773V15.4277C18.224 16.1309 17.9766 16.7168 17.5078 17.1855C17.0391 17.6543 16.4531 17.9017 15.75 17.9277H3.25C2.54688 17.9017 1.96094 17.6543 1.49219 17.1855C1.02344 16.7168 0.776042 16.1309 0.75 15.4277V2.92773C0.776042 2.22461 1.02344 1.63867 1.49219 1.16992C1.96094 0.701172 2.54688 0.453776 3.25 0.427734H15.75ZM16.375 15.4277V2.92773C16.349 2.53711 16.1406 2.32878 15.75 2.30273H3.25C2.85938 2.32878 2.65104 2.53711 2.625 2.92773V15.4277C2.65104 15.8184 2.85938 16.0267 3.25 16.0527H15.75C16.1406 16.0267 16.349 15.8184 16.375 15.4277ZM11.0625 12.3027C11.6354 12.3548 11.9479 12.6673 12 13.2402C11.9479 13.8132 11.6354 14.1257 11.0625 14.1777H7.9375C7.36458 14.1257 7.05208 13.8132 7 13.2402C7.05208 12.6673 7.36458 12.3548 7.9375 12.3027H8.5625V9.80273H8.25C7.67708 9.75065 7.36458 9.43815 7.3125 8.86523C7.36458 8.29232 7.67708 7.97982 8.25 7.92773H9.5C10.0729 7.97982 10.3854 8.29232 10.4375 8.86523V12.3027H11.0625ZM9.5 6.67773C9.13542 6.67773 8.83594 6.56055 8.60156 6.32617C8.36719 6.0918 8.25 5.79232 8.25 5.42773C8.25 5.06315 8.36719 4.76367 8.60156 4.5293C8.83594 4.29492 9.13542 4.17773 9.5 4.17773C9.86458 4.17773 10.1641 4.29492 10.3984 4.5293C10.6328 4.76367 10.75 5.06315 10.75 5.42773C10.75 5.79232 10.6328 6.0918 10.3984 6.32617C10.1641 6.56055 9.86458 6.67773 9.5 6.67773Z'
                fill='#201C26'
              />
            </svg>
          )}
        </div>
      </span>
      <ReactTooltip
        html
        id={id.current}
        place={place || 'top'}
        type='dark'
        effect='solid'
      >
        {`${
          title
            ? `<h5 class="d-flex gap-2"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.75 0.427734C16.4531 0.453776 17.0391 0.701172 17.5078 1.16992C17.9766 1.63867 18.224 2.22461 18.25 2.92773V15.4277C18.224 16.1309 17.9766 16.7168 17.5078 17.1855C17.0391 17.6543 16.4531 17.9017 15.75 17.9277H3.25C2.54688 17.9017 1.96094 17.6543 1.49219 17.1855C1.02344 16.7168 0.776042 16.1309 0.75 15.4277V2.92773C0.776042 2.22461 1.02344 1.63867 1.49219 1.16992C1.96094 0.701172 2.54688 0.453776 3.25 0.427734H15.75ZM16.375 15.4277V2.92773C16.349 2.53711 16.1406 2.32878 15.75 2.30273H3.25C2.85938 2.32878 2.65104 2.53711 2.625 2.92773V15.4277C2.65104 15.8184 2.85938 16.0267 3.25 16.0527H15.75C16.1406 16.0267 16.349 15.8184 16.375 15.4277ZM11.0625 12.3027C11.6354 12.3548 11.9479 12.6673 12 13.2402C11.9479 13.8132 11.6354 14.1257 11.0625 14.1777H7.9375C7.36458 14.1257 7.05208 13.8132 7 13.2402C7.05208 12.6673 7.36458 12.3548 7.9375 12.3027H8.5625V9.80273H8.25C7.67708 9.75065 7.36458 9.43815 7.3125 8.86523C7.36458 8.29232 7.67708 7.97982 8.25 7.92773H9.5C10.0729 7.97982 10.3854 8.29232 10.4375 8.86523V12.3027H11.0625ZM9.5 6.67773C9.13542 6.67773 8.83594 6.56055 8.60156 6.32617C8.36719 6.0918 8.25 5.79232 8.25 5.42773C8.25 5.06315 8.36719 4.76367 8.60156 4.5293C8.83594 4.29492 9.13542 4.17773 9.5 4.17773C9.86458 4.17773 10.1641 4.29492 10.3984 4.5293C10.6328 4.76367 10.75 5.06315 10.75 5.42773C10.75 5.79232 10.6328 6.0918 10.3984 6.32617C10.1641 6.56055 9.86458 6.67773 9.5 6.67773Z" fill="#201C26"/>
</svg>${title}</h5>`
            : ''
        }${tooltip}`}
      </ReactTooltip>
    </span>
  )
}

export default Tooltip
