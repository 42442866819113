export function parseError(e?: any) {
  if (typeof e?.data === 'string') {
    return e.data
  }
  if (e?.status) {
    return `Error: ${e.status}`
  }

  return e?.message || e
}
