import classNames from 'classnames'
import { FC, ReactNode } from 'react'

// const paddingMap: Record<keyof CardType['padding'], string> = {
//   lg: 'px-5 py-2',
//   sm: 'px-5 py-2',
// }

export type CardType = {
  theme?: string
  padding?: number
  rounded?: boolean
  border?: boolean
  shadow?: 0 | 1 | 2 | 3
  className?: string
  onClick?: () => void
  footer?: ReactNode
  style?: object
  footerClassName?: string
  header?: ReactNode
  headerClassName?: string
}

const Card: FC<CardType> = ({
  children,
  className,
  footer,
  footerClassName,
  header,
  headerClassName,
  onClick,
  padding = 5,
  rounded = true,
  shadow = 2,
  style,
  theme = 'grey3',
}) => {
  return (
    <div
      style={style}
      onClick={onClick}
      className={classNames(
        className,
        'h-100',
        'card position-relative d-flex flex-column',
        `bg-${theme}`,
        `shadow-${shadow}`,
        rounded ? 'rounded' : '',
      )}
    >
      {header && (
        <div
          className={classNames(
            'card-header',
            headerClassName,
            'rounded-top',
            padding && `p-${padding}`,
          )}
        >
          {header}
        </div>
      )}
      <div
        className={classNames(
          padding && `p-${padding}`,
          `d-flex flex-column flex-fill`,
        )}
      >
        {children}
      </div>
      {footer && (
        <div
          className={classNames(
            'card-footer',
            footerClassName,
            'rounded-bottom',
            padding && `p-${padding}`,
          )}
        >
          {footer}
        </div>
      )}
    </div>
  )
}

export default Card
