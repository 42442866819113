import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { Utils } from 'common/utils'

export const paymentSourceService = service
  .enhanceEndpoints({ addTagTypes: ['PaymentSource'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPaymentSources: builder.query<
        Res['paymentSources'],
        Req['getPaymentSources']
      >({
        providesTags: [{ id: 'LIST', type: 'PaymentSource' }],
        query: (query: Req['getPaymentSources']) => ({
          url: `customers/payment-sources?${Utils.toParam(query)}`,
        }),
      }),
    }),
  })

export async function getPaymentSources(
  store: any,
  data: Req['getPaymentSources'],
  options?: Parameters<
    typeof paymentSourceService.endpoints.getPaymentSources.initiate
  >[1],
) {
  return store.dispatch(
    paymentSourceService.endpoints.getPaymentSources.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetPaymentSourcesQuery,
  // END OF EXPORTS
} = paymentSourceService

/* Usage examples:
const { data, isLoading } = useGetPaymentSourcesQuery({ id: 2 }, {}) //get hook
const [createPaymentSources, { isLoading, data, isSuccess }] = useCreatePaymentSourcesMutation() //create hook
paymentSourceService.endpoints.getPaymentSources.select({id: 2})(store.getState()) //access data from any function
*/
