import { FC, useEffect, useState } from 'react'
import usePreview from 'project/usePreview'
import { useRouter } from 'next/router'
import Button from './base/forms/Button'

type PreviewType = {}

const PreviewBar: FC<PreviewType> = ({}) => {
  const { preview, setPreview } = usePreview()
  const [ready, setReady] = useState(false)
  const router = useRouter()
  useEffect(() => {
    setReady(true)
  }, [])
  return preview && ready ? (
    <div>
      <div key='preview' className='text-center bg-dark py-2 text-white'>
        You are in preview mode.
        <Button
          className='ms-2'
          onClick={() => {
            router.replace('/').then(() => {
              setPreview(false)
            })
          }}
          theme='secondary'
        >
          Exit
        </Button>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default PreviewBar
