import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['devSettings']

export const initialDevSettingsState = {
  enabled: false,
  environment: 'production',
  testData: false,
} as InitialStateType

export const devSettingsSlice = createSlice({
  initialState: initialDevSettingsState,
  name: 'devSettings',
  reducers: {
    setDevSettings(state, action: PayloadAction<Req['setDevSettings']>) {
      state = action.payload
      return state
    },
  },
})

export const devSettingsActions = devSettingsSlice.actions
export const useDevSettingsActions = () => {
  const dispatch = useDispatch()
  const setDevSettings = useCallback(
    (payload: Req['setDevSettings']) => {
      return dispatch(devSettingsActions.setDevSettings(payload))
    },
    [dispatch],
  )
  return { setDevSettings }
}

const selectDevSettings = (state: StoreStateType) => state.devSettings

export const useDevSettings = () => {
  const { setDevSettings } = useDevSettingsActions()
  const devSettings = useSelector(selectDevSettings)
  return useMemo(
    () => ({ devSettings, setDevSettings }),
    [setDevSettings, devSettings],
  )
}
