import { Address } from 'common/types/requests'
import dayjs, { Dayjs } from 'dayjs'

export const Format = {
  address: (address: Address | null, lastLineNewLine?: boolean) => {
    if (!address) return ''
    if (lastLineNewLine) {
      return `${[
        address.address_line_1,
        address.address_line_2,
        address.address_line_3,
        address.city,
      ]
        .filter((v) => !!v)
        .join(', ')}\n${address.postal_code}`
    }
    return [
      address.address_line_1,
      address.address_line_2,
      address.address_line_3,
      address.city,
      address.postal_code,
    ]
      .filter((v) => !!v)
      .join(', ')
  },
  camelCase(val: string | null | undefined): string {
    // hello world > Hello world
    return val ? val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() : ''
  },

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  },

  capitalizeWords(string: string) {
    return string
      .split(' ')
      .map((v) => Format.capitalizeFirstLetter(v))
      .join(' ')
  },
  enumeration: {
    get(value: string | null | undefined): string {
      // MY_CONSTANT > My constant
      if (!value) {
        return ''
      }
      return Format.camelCase(value.replace(/_/g, ' '))
    },
    set(value: string): string {
      // My Constant > MY_CONSTANT
      return value ? value.replace(/ /g, '_').toUpperCase() : ''
    },
  },
  money: (value: number) => {
    if (value) {
      return (value / 100)?.toLocaleString('en-GB', {
        currency: 'GBP',
        style: 'currency',
      })
    }
    return (0).toLocaleString('en-GB', {
      currency: 'GBP',
      style: 'currency',
    })
  },
  prettyDay(day: Dayjs) {
    const now = dayjs(Date.now())
    let display = ''
    if (day.date() === now.date()) {
      display = 'Today'
    } else if (day.date() - now.date() === -1) {
      display = 'Yesterday'
    } else if (day.date() - now.date() === 1) {
      display = 'Tomorrow'
    } else {
      display = day.format('Do MMMM')
    }
    return display
  },
}
