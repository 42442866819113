import React, { FC } from 'react'
import cn from 'classnames'
import classNames from 'classnames'

interface Checkbox {
  label?: string
  className?: string
  value?: string
  disabled?: boolean
  id: string
  checked: boolean
}

const Checkbox: FC<Checkbox> = ({
  checked,
  className,
  disabled,
  id,
  label,
  value,
}) => {
  return (
    <>
      <div className={cn('lh-input d-flex align-items-center', className)}>
        <label
          className={classNames('me-2 body-secondary form-check-label', {
            disabled,
          })}
        >
          {label}
        </label>
        <div style={{ height: 18, width: 18 }}>
          <input
            disabled={disabled}
            className='form-check-input'
            type='checkbox'
            value={value}
            checked={checked}
            id={id}
          />
        </div>
      </div>
    </>
  )
}

Checkbox.displayName = 'Checkbox'
export default Checkbox
