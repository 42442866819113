import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['thing'] | null

const initialState = null as InitialStateType

export const thingSlice = createSlice({
  initialState,
  name: 'thing',
  reducers: {
    kycstepThing(state, action: PayloadAction<Req['kycstepThing']>) {
      state = action.payload
      return state
    },
  },
})

export const thingActions = thingSlice.actions
export const useThingActions = () => {
  const dispatch = useDispatch()
  const kycstepThing = useCallback(
    (payload: Req['kycstepThing']) => {
      return dispatch(thingActions.kycstepThing(payload))
    },
    [dispatch],
  )
  return { kycstepThing }
}

const selectThing = (state: StoreStateType) => state.thing

export const useThing = () => {
  const { kycstepThing } = useThingActions()
  const thing = useSelector(selectThing)
  return useMemo(() => ({ kycstepThing, thing }), [kycstepThing, thing])
}
