import React, { FC, useEffect, useState } from 'react'
import ActiveLink from 'components/util/ActiveLink'

import Icon, { IconName } from 'components/Icon'
import Router, { useRouter } from 'next/router'
import { useLogoutMutation, useUser } from 'common/services/useUser'
import Link from 'next/link'
import Unread from 'components/Unread'
import classNames from 'classnames'
import { Collapse } from 'reactstrap'
import Row from 'components/base/grid/Row'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { useGetMailCountsQuery } from 'common/services/useMailCount'
import { useFlags } from 'flagsmith/react'
import cognito from 'common/cognito'
import Button from 'components/base/forms/Button'
import { useUserData } from 'common/services/useUserData'
import cookies from 'js-cookie'
import { PlanSlug, productLinks } from 'components/WebsiteFooter'
import { Project } from 'common/project'
import dataRelay from 'data-relay'
import { useGetKycMembersQuery } from 'common/services/useKycMember'

type ComponentType = {}

type SidebarItemProps = {
  label: string
  badgeCount?: number
  size?: '' | 'sm'
  icon?: IconName
  subMenu?: React.ReactNode
  onClick?: () => void
  path?: string
  activePath: string
  className?: string
  hideIcon?: boolean
  unread?: number
  expandable?: boolean
  match?: any
  'data-bs-toggle'?: boolean
}
const SidebarItem: FC<SidebarItemProps> = ({
  activePath,
  className = 'mb-4',
  expandable,
  hideIcon,
  icon,
  label,
  match,
  onClick,
  path,
  size = '',
  subMenu,
  unread,
  ...rest
}) => {
  const isActive = match ? activePath.match(match) : activePath === path
  const Tag = path
    ? (ActiveLink as any)
    : (props: any) => React.createElement('div', props, props.children)
  return (
    <li className={`w-100`}>
      <Tag
        {...rest}
        match={match}
        onClick={onClick}
        className={classNames(
          size === 'sm' && 'sidebar-item--small',
          'cursor-pointer sidebar-item rounded px-5 text-decoration-none',
          className,
        )}
        href={path}
      >
        <div className='flex-row justify-content-between align-items-center gap-3'>
          <div className='flex-row align-items-center gap-2'>
            {icon && <Icon fill='white' name={icon} />}
            <span
              className={`text-decoration-none text-white`}
              aria-current='page'
            >
              {label}
            </span>
            {!!unread && <Unread className='ms-2'>{unread}</Unread>}
          </div>
          {expandable ? (
            <span
              className={classNames(
                'rotate-chevron',
                isActive ? 'rotate-180' : '',
              )}
            >
              <span className='fa fa-chevron-down text-white' />
            </span>
          ) : (
            !hideIcon && <span className='fa fa-chevron-right text-white' />
          )}
        </div>
      </Tag>

      {subMenu}
    </li>
  )
}
const SidebarBrand: FC<{}> = ({ children }) => {
  return (
    <Link className='text-decoration-none' href={'/'}>
      <div className='flex-row align-items-center justify-content-between gap-3'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={40}
          height={40}
          fill='none'
        >
          <path
            fill='#fff'
            d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Zm20 16.748c9.235 0 16.748-7.513 16.748-16.748 0-9.235-7.513-16.748-16.748-16.748-9.235 0-16.748 7.513-16.748 16.748a16.662 16.662 0 0 0 3.314 9.99 210.648 210.648 0 0 0 4.664-4.421v-.003C5.095 18.425 7.66 13.327 12.396 8.67l3.338 3.36 3.453-3.627a2.676 2.676 0 1 1 3.876 3.69l-3.557 3.737 2.114 2.128 2.317 2.333 3.451-3.627a2.676 2.676 0 1 1 3.877 3.69l-3.556 3.736 3.135 3.157C26.23 31.873 21.2 34.38 14.28 28.573l-.007.01a192.347 192.347 0 0 1-4.737 4.485A16.667 16.667 0 0 0 20 36.748Z'
          />
        </svg>
        {children}
      </div>
    </Link>
  )
}
const SidebarGroup: FC<SidebarItemProps> = ({ children, ...props }) => {
  const router = useRouter()
  const isOpen = !!router.asPath.match(props.match)
  return (
    <>
      <SidebarItem expandable {...props} />
      <Collapse isOpen={isOpen}>
        <ul className='flex-column collapse show nav ps-4'>{children}</ul>
      </Collapse>
    </>
  )
}

const Sidebar: FC<ComponentType> = ({}) => {
  const router = useRouter()
  const activePath = router.pathname
  const { data } = useUser({})
  const [logout] = useLogoutMutation({})
  const [collapsed, setCollapsed] = useState(true)
  const { defaultSubscriptionId, hasPermission, subscriptionDetail } =
    useDefaultSubscription()
  useEffect(() => {
    if (!collapsed) {
      window.scrollTo({ top: 0 })
    }
  }, [collapsed])
  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setCollapsed(true)
    })
  }, [])
  const {
    data: kycMembers,
    isUninitialized,
    refetch,
  } = useGetKycMembersQuery(
    { subscription: `${defaultSubscriptionId}` },
    { refetchOnMountOrArgChange: true, skip: !defaultSubscriptionId },
  )

  const { data: unread } = useGetMailCountsQuery(
    // @ts-ignore
    { subscription_id: defaultSubscriptionId },
    { skip: !defaultSubscriptionId },
  )
  const {
    devmode,
    feature_business,
    feature_kyc,
    feature_marketplace,
    feature_teams,
    start_another_business,
  } = useFlags([
    'devmode',
    'feature_business',
    'feature_kyc',
    'feature_marketplace',
    'feature_teams',
    'start_another_business',
  ])
  const { data: user } = useUser({})
  const { userData } = useUserData()
  const checkout = () => {
    if (!user) {
      return
    }
    const data = {
      'address': {
        'address_line_1': '',
        'address_line_2': '',
        'city': '',
        'country': '',
        'postal_code': '',
        ...(subscriptionDetail?.address || {}),
        'first_name': userData?.first_name || '',
        'last_name': userData?.last_name || '',
      },
      'billing_address': {
        'address_line_1': '',
        'address_line_2': '',
        'city': '',
        'country': '',
        'postal_code': '',
        ...(subscriptionDetail?.address || {}),
        'first_name': userData?.first_name || '',
        'last_name': userData?.last_name || '',
      },
      'business': {
        'business_type': 'LIMITED_COMPANY',
        'company_number': '',
        'country_of_corporation': 'GB',
        'description': '',
        'legal_name': '',
        'trading_name': '',
      },
      'customer_email': user?.username || '',
      'first_name': userData?.first_name || '',
      'last_name': userData?.last_name || '',
      'payment_type': '',
      'phone_number': '',
      'product_id': '',
      'reference_id': '',
    }
    const links = productLinks()
    const link = links.find((v) => {
      return v.title.includes(subscriptionDetail?.name)
    })
    const foundSlug = Object.values(PlanSlug).find((v) =>
      link?.href.includes(v),
    )
    const cookieJSON: Record<string, string> = {
      referrer: user.username,
    }

    if (start_another_business.value) {
      cookieJSON.coupon = `${start_another_business.value}`
    }
    if (foundSlug) {
      data.product_id = foundSlug
      cookieJSON.bag = JSON.stringify({
        'addons': [],
        'duration': 'month',
        'slug': foundSlug,
      })
    }
    if (Project.dataRelay) {
      try {
        dataRelay.sendEvent(
          {
            company: data.business.legal_name || data.business.trading_name,
            email: data.customer_email,
            event: 'Second business click',
            product: data.product_id,
          },
          { apiKey: Project.dataRelay },
        )
      } catch (e) {}
    }
    cookieJSON.checkout = JSON.stringify(data)
    const cookieUrl = encodeURIComponent(btoa(JSON.stringify(cookieJSON)))
    if (foundSlug) {
      window.open(`${Project.hoxtonmixUrl}/checkout?c=${cookieUrl}`, '_blank')
    } else {
      window.open(`${Project.hoxtonmixUrl}?c=${cookieUrl}`, '_blank')
    }
  }
  const kycCompletedLegacy =
    subscriptionDetail?.kyc_status?.kyc_completed &&
    kycMembers?.results?.length === 0
  return (
    <div
      className={classNames(
        'min-vh-md-100',
        { 'min-vh-100 d-flex flex-column': !collapsed },
        'col-auto d-flex flex-column px-5 pt-md-10 w-100 w-md-auto sidebar text-white bg-black color-white sidebar',
      )}
    >
      <Row className='d-md-none nav align-items-center justify-content-between'>
        <button
          onClick={() => {
            setCollapsed(!collapsed)
          }}
          className='d-md-none navbar-toggler'
          type='button'
        >
          {!collapsed ? (
            <Icon width={24} height={16} fill='white' name='close' />
          ) : (
            <Icon fill='white' name='hamburger-menu' />
          )}
        </button>
        <SidebarBrand />
        <div />
      </Row>

      {!!data && (
        <div
          className={classNames(
            {
              'd-flex flex-column flex-fill': !collapsed,
              'd-none': collapsed,
            },
            'd-md-flex flex-column flex-fill',
          )}
        >
          <ul
            className='nav flex-fill d-flex nav-pills pt-5 pt-md-0 flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start'
            id='menu'
          >
            <li className={`mb-md-12 d-md-block d-none`}>
              <SidebarBrand>
                <h1 className='text-white mb-0'>Portal</h1>
              </SidebarBrand>
            </li>
            <SidebarItem
              label='Dashboard'
              icon='dashboard'
              path='/'
              activePath={activePath}
            />
            <>
              {hasPermission('READ_MAIL') && (
                <SidebarItem
                  label='Mail'
                  icon='mail'
                  path='/mail'
                  activePath={activePath}
                  unread={unread?.count}
                />
              )}
            </>
            {!subscriptionDetail?.is_partner_customer &&
              hasPermission('READ_INVOICE') && (
                <SidebarItem
                  label='Account & Billing'
                  path='/account-billing'
                  icon='account'
                  activePath={activePath}
                />
              )}

            {!subscriptionDetail?.is_partner_customer && (
              <>
                {!kycCompletedLegacy &&
                feature_kyc.enabled &&
                hasPermission('READ_MEMBER') &&
                !subscriptionDetail?.kyc_status?.kyc_link ? (
                  <SidebarGroup
                    expandable
                    className='mb-2'
                    icon='business-settings'
                    label='Business Settings'
                    path='/business'
                    match={/business/}
                    activePath={activePath}
                  >
                    <SidebarItem
                      size='sm'
                      label='Verify Owners'
                      path='/business/owners'
                      activePath={activePath}
                    />
                    {/*<SidebarItem*/}
                    {/*  size='sm'*/}
                    {/*  label='Business Directors'*/}
                    {/*  path='/business/directors/'*/}
                    {/*  activePath={activePath}*/}
                    {/*/>*/}
                  </SidebarGroup>
                ) : (
                  feature_business.enabled && (
                    <SidebarItem
                      icon='business-settings'
                      label='Business Settings'
                      path='/business/'
                      match={/business/}
                      activePath={activePath}
                    />
                  )
                )}
              </>
            )}
            {feature_marketplace.enabled && (
              <SidebarItem
                label='Marketplace'
                path='/marketplace'
                icon='market'
                activePath={activePath}
              />
            )}

            {feature_teams.enabled && hasPermission('READ_MEMBER') && (
              <SidebarItem
                label='Team'
                icon='team'
                path='/team'
                activePath={activePath}
              />
            )}
            {devmode.enabled && (
              <SidebarItem
                label='Developer Settings'
                path='/developer-settings'
                icon='code'
                activePath={activePath}
              />
            )}
            <SidebarItem
              onClick={() => {
                cognito.logout().finally(() => {
                  logout({})
                })
              }}
              label='Logout'
              icon='logout'
              activePath={activePath}
            />
          </ul>

          <ul
            className='nav d-flex nav-pills pt-5 pt-md-0 flex-column mb-sm-auto mb-0 align-items-center'
            id='menu'
          >
            {start_another_business.enabled && (
              <div
                style={{ width: 200 }}
                className='roundedLg p-4 mb-8 d-flex flex-column gap-4 p-2 bg-blue text-white text-center'
              >
                <h4 className='mb-0'>Have another business?</h4>
                <div className='fs-sm px-2'>
                  Sign up with our quick registration process and enjoy 20% off
                  your first year.
                </div>
                <Button onClick={checkout} theme='white'>
                  Sign up
                </Button>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Sidebar
