import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'
import useSubscriptions from 'common/useSubscriptions'
import { Permission } from 'common/types/responses'
import { Constants } from 'common/utils'

const initialState = ''

export const defaultSubscriptionIdSlice = createSlice({
  initialState,
  name: 'defaultSubscriptionId',
  reducers: {
    setDefaultSubscriptionId(
      state,
      action: PayloadAction<Req['setDefaultSubscriptionId']>,
    ) {
      state = action.payload
      return state
    },
  },
})

export const defaultSubscriptionIdActions = defaultSubscriptionIdSlice.actions
export const useDefaultSubscriptionActions = () => {
  const dispatch = useDispatch()
  const setDefaultSubscriptionId = useCallback(
    (payload: Req['setDefaultSubscriptionId']) => {
      return dispatch(
        defaultSubscriptionIdActions.setDefaultSubscriptionId(payload),
      )
    },
    [dispatch],
  )
  return { setDefaultSubscriptionId }
}

const selectDefaultSubscriptionId = (state: StoreStateType) =>
  state.defaultSubscriptionId

export const useDefaultSubscription = () => {
  const subscriptions = useSubscriptions()
  const { setDefaultSubscriptionId } = useDefaultSubscriptionActions()
  let defaultSubscriptionId = useSelector(selectDefaultSubscriptionId)
  if (!defaultSubscriptionId && subscriptions?.length) {
    // @ts-ignore
    defaultSubscriptionId =
      subscriptions?.find((v) => !v.is_cancelled)?.id || subscriptions?.[0].id
  }
  if (defaultSubscriptionId && subscriptions?.length) {
    // @ts-ignore
    if (!subscriptions?.find((v) => v.id === defaultSubscriptionId)) {
      // @ts-ignore
      defaultSubscriptionId = subscriptions?.[0].id
    }
  }
  const subscriptionDetail = useMemo(() => {
    return subscriptions?.find((subscription) => {
      // @ts-ignore
      return subscription.id === defaultSubscriptionId
    })
  }, [subscriptions, defaultSubscriptionId])
  const hasPermission = useCallback(
    (permission: Permission['code']) => {
      const role = subscriptionDetail?.company?.role
      if (subscriptionDetail?.company?.is_owner) {
        return true
      }
      return (Constants.simulate.permissions || role?.permissions)?.find(
        (v) => v === permission,
      )
    },
    [subscriptionDetail],
  )
  return useMemo(
    () => ({
      defaultSubscriptionId,
      hasPermission,
      setDefaultSubscriptionId,
      subscriptionDetail,
    }),
    [
      setDefaultSubscriptionId,
      hasPermission,
      defaultSubscriptionId,
      subscriptionDetail,
    ],
  )
}
