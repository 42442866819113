import {
  Company,
  CompanyDetail,
  CompanyIDStatus,
  CompanySubscription,
  Res,
} from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const kycStatusService = service
  .enhanceEndpoints({ addTagTypes: ['KycStatus'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getKycStatus: builder.query<Res['kycStatus'], Req['getKycStatus']>({
        providesTags: (_, _2, res) => [{ id: res?.id, type: 'KycStatus' }],
        query: (query: Req['getKycStatus']) => ({
          url: `customers/companies/${query.id}/kyc/status?subscription_id=${query.subscription_id}`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getKycStatus(
  store: any,
  data: Req['getKycStatus'],
  options?: Parameters<
    typeof kycStatusService.endpoints.getKycStatus.initiate
  >[1],
) {
  return store.dispatch(
    kycStatusService.endpoints.getKycStatus.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetKycStatusQuery,
  // END OF EXPORTS
} = kycStatusService

export const kycNeedsVerifying = (
  subscription: CompanySubscription | undefined,
) => {
  if (!subscription) {
    return false
  }
  if (
    subscription?.kyc_status?.ltd_company_id_status === 'IdCheckedConfirmed'
  ) {
    return false
  } else if (subscription.kyc_status?.kyc_completed === true) {
    return false
  }
  return true
}

/* Usage examples:
const { data, isLoading } = useGetKycStatusQuery({ id: 2 }, {}) //get hook
const [createKycStatus, { isLoading, data, isSuccess }] = useCreateKycStatusMutation() //create hook
kycStatusService.endpoints.getKycStatus.select({id: 2})(store.getState()) //access data from any function
*/
