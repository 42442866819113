import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['userAnalytics']

export const initialUserAnalytics = {
  hasAddedPass: false,
  loginCount: 0,
  walletPrompt: null,
} as InitialStateType

export const userAnalyticsSlice = createSlice({
  initialState: initialUserAnalytics,
  name: 'userAnalytics',
  reducers: {
    incrementLogins(state, action: PayloadAction<Req['incrementLogins']>) {
      state = { ...state, loginCount: (state.loginCount || 0) + 1 }
      return state
    },
    setUserAnalytics(state, action: PayloadAction<Req['setUserAnalytics']>) {
      state = { ...state, ...action.payload }
      return state
    },
  },
})

export const userAnalyticsActions = userAnalyticsSlice.actions
export const useUserAnalyticsActions = () => {
  const dispatch = useDispatch()
  const setUserAnalytics = useCallback(
    (payload: Req['setUserAnalytics']) => {
      return dispatch(userAnalyticsActions.setUserAnalytics(payload))
    },
    [dispatch],
  )
  const incrementLogins = useCallback(
    (payload: Req['incrementLogins']) => {
      return dispatch(userAnalyticsActions.incrementLogins(payload))
    },
    [dispatch],
  )
  return { incrementLogins, setUserAnalytics }
}

const selectUserAnalytics = (state: StoreStateType) => state.userAnalytics

export const useUserAnalytics = () => {
  const { incrementLogins, setUserAnalytics } = useUserAnalyticsActions()
  const userAnalytics: Res['userAnalytics'] = useSelector(selectUserAnalytics)
  return useMemo(
    () => ({ incrementLogins, setUserAnalytics, userAnalytics }),
    [setUserAnalytics, userAnalytics, incrementLogins],
  )
}
