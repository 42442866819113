import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getCompany } from './useCompany'
import { getStore } from 'common/store'
import { getUser } from './useUser'

export const topUpService = service
  .enhanceEndpoints({ addTagTypes: ['TopUp'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createTopUp: builder.mutation<Res['topUp'], Req['createTopUp']>({
        invalidatesTags: [{ id: 'LIST', type: 'TopUp' }],
        query: (query: Req['createTopUp']) => ({
          body: query,
          method: 'POST',
          url: `customers/companies/${query.id}/topup`,
        }),
        transformErrorResponse: async (res, _, req) => {
          await getCompany(getStore(), { id: req.id })
          return res
        },
        transformResponse: async (res, _, req) => {
          await getUser(getStore(), {}, { forceRefetch: true })
          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createTopUp(
  store: any,
  data: Req['createTopUp'],
  options?: Parameters<typeof topUpService.endpoints.createTopUp.initiate>[1],
) {
  store.dispatch(topUpService.endpoints.createTopUp.initiate(data, options))
  return Promise.all(store.dispatch(topUpService.util.getRunningQueriesThunk()))
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateTopUpMutation,
  // END OF EXPORTS
} = topUpService

/* Usage examples:
const { data, isLoading } = useGetTopUpQuery({ id: 2 }, {}) //get hook
const [createTopUp, { isLoading, data, isSuccess }] = useCreateTopUpMutation() //create hook
topUpService.endpoints.getTopUp.select({id: 2})(store.getState()) //access data from any function
*/
