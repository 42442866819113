import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getUser } from './useUser'
import { getStore } from 'common/store'

export const verifyTopupService = service
  .enhanceEndpoints({ addTagTypes: ['VerifyTopup'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createVerifyTopup: builder.mutation<
        Res['verifyTopup'],
        Req['createVerifyTopup']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'VerifyTopup' }],
        query: (query: Req['createVerifyTopup']) => ({
          body: query,
          method: 'POST',
          url: `customers/companies/${query.id}/topup/verify?hosted_page=${query.hosted_page}`,
        }),
        transformResponse: async (res) => {
          await getUser(getStore(), {}, { forceRefetch: true })
          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createVerifyTopup(
  store: any,
  data: Req['createVerifyTopup'],
  options?: Parameters<
    typeof verifyTopupService.endpoints.createVerifyTopup.initiate
  >[1],
) {
  return store.dispatch(
    verifyTopupService.endpoints.createVerifyTopup.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateVerifyTopupMutation,
  // END OF EXPORTS
} = verifyTopupService

/* Usage examples:
const { data, isLoading } = useGetVerifyTopupQuery({ id: 2 }, {}) //get hook
const [createVerifyTopup, { isLoading, data, isSuccess }] = useCreateVerifyTopupMutation() //create hook
verifyTopupService.endpoints.getVerifyTopup.select({id: 2})(store.getState()) //access data from any function
*/
