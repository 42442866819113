import { FC } from 'react'
import {
  longCardStatus,
  prettyCardStatus,
  usePaymentWarning,
} from 'common/hooks/usePaymentWarning'
import Icon from 'components/Icon'
import Button from 'components/base/forms/Button'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { useGetManageSubscriptionQuery } from 'common/services/useManageSubscription'
import cookies from 'js-cookie' // we need this to make JSX compile

type PaymentWarningType = {
  theme?: 'default' | 'long'
}

const PaymentWarning: FC<PaymentWarningType> = ({ theme }) => {
  const { paymentWarning, status } = usePaymentWarning()
  const { hasPermission, subscriptionDetail } = useDefaultSubscription()
  const canManage = hasPermission('MANAGE_BILLING')
  const { data: manageSubscriptionUrl, refetch } =
    useGetManageSubscriptionQuery(
      {
        id: subscriptionDetail?.company_id,
      },
      {
        skip: !subscriptionDetail?.company_id,
      },
    )

  const handleManageSubscription = () => {
    refetch()
    try {
      cookies.remove('cbt', { domain: 'checkout.hoxtonmix.com' })
    } catch (e) {}
    window.open(manageSubscriptionUrl?.access_url || '', '_blank')
  }
  if (!paymentWarning || status === 'valid') {
    return null
  }

  if (theme === 'long') {
    return (
      <div className='flex-row me-10 align-items-center'>
        <div className='flex-fill text-sm'>
          <div className='flex-row align-items-center'>
            <Icon fill='white' name={'warning'} />
            <h1 className='text-semibold mb-0 ms-2'>
              Card {prettyCardStatus(status)}
            </h1>
          </div>
          <div>{longCardStatus(status)}</div>
        </div>
        {canManage && (
          <Button theme='lightOutline' onClick={handleManageSubscription}>
            Manage
          </Button>
        )}
      </div>
    )
  }
  return (
    <div className='flex-row align-items-center'>
      <div className='flex-fill text-sm'>
        <div className='flex-row align-items-center'>
          <Icon fill='white' name={'warning'} />
          <span className='text-bold ms-1'>
            Card {prettyCardStatus(status)}
          </span>
        </div>
        {canManage && <div>Update payment method</div>}
      </div>
      {canManage && (
        <Button theme='lightOutline' onClick={handleManageSubscription}>
          Manage
        </Button>
      )}
    </div>
  )
}

export default PaymentWarning
