import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { Utils } from 'common/utils'

export const mailCountService = service
  .enhanceEndpoints({ addTagTypes: ['MailCount'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMailCounts: builder.query<Res['mailCounts'], Req['getMailCounts']>({
        providesTags: [{ id: 'LIST', type: 'MailCount' }],
        query: (q) => ({
          url: `mailbox/mails/count?${Utils.toParam(q)}`,
        }),
      }),
      readMailCount: builder.mutation<Res['mailCount'], Req['readMailCount']>({
        invalidatesTags: [
          { id: 'LIST', type: 'MailCount' },
          { id: 'LIST', type: 'Mail' },
        ],
        query: (query: Req['readMailCount']) => ({
          body: query,
          method: 'PUT',
          url: `mailbox/mails/read`,
        }),
      }),
      unreadMailCount: builder.mutation<Res['mailCount'], Req['readMailCount']>(
        {
          invalidatesTags: [
            { id: 'LIST', type: 'MailCount' },
            { id: 'LIST', type: 'Mail' },
          ],
          query: (query: Req['unreadMailCount']) => ({
            body: query,
            method: 'PUT',
            url: `mailbox/mails/unread`,
          }),
        },
      ),
      // END OF ENDPOINTS
    }),
  })

export async function getMailCounts(
  store: any,
  data: Req['getMailCounts'],
  options?: Parameters<
    typeof mailCountService.endpoints.getMailCounts.initiate
  >[1],
) {
  return store.dispatch(
    mailCountService.endpoints.getMailCounts.initiate(data, options),
  )
}
export async function readMailCount(
  store: any,
  data: Req['readMailCount'],
  options?: Parameters<
    typeof mailCountService.endpoints.readMailCount.initiate
  >[1],
) {
  return store.dispatch(
    mailCountService.endpoints.readMailCount.initiate(data, options),
  )
}
export async function unreadMailCount(
  store: any,
  data: Req['unreadMailCount'],
  options?: Parameters<
    typeof mailCountService.endpoints.unreadMailCount.initiate
  >[1],
) {
  return store.dispatch(
    mailCountService.endpoints.unreadMailCount.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetMailCountsQuery,
  // END OF EXPORTS
} = mailCountService

/* Usage examples:
const { data, isLoading } = useGetMailCountsQuery({ id: 2 }, {}) //get hook
const [readMailCounts, { isLoading, data, isSuccess }] = useReadMailCountsMutation() //read hook
mailCountService.endpoints.getMailCounts.select({id: 2})(store.getState()) //access data from any function
*/
