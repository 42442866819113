import { FC, ReactNode, useEffect } from 'react'
import { useDefaultSubscription } from './services/useDefaultSubscription'
import { getApi } from './api-common'
import { useUser } from './services/useUser'
import dayjs from 'dayjs'
import { useUserData } from './services/useUserData'
import useSubscriptions from './useSubscriptions'

type CrispProviderType = { children?: ReactNode }

const CrispProvider: FC<CrispProviderType> = ({ children }) => {
  const { data } = useUser({})
  const { userData } = useUserData()
  const { subscriptionDetail } = useDefaultSubscription()
  const subscriptions = useSubscriptions()
  useEffect(() => {
    try {
      if (subscriptionDetail && data) {
        const isMonthly = subscriptionDetail.billing_period === 1
        const subId = subscriptionDetail?.company?.subscriptions?.find(
          (v) => v.id === subscriptionDetail.id,
        )?.chargebee_id
        const _userData = {
          cancelled: `${subscriptionDetail.is_cancelled}`,
          chargebee: subId
            ? `https://hoxtonmix.chargebee.com/admin-console/subscriptions/${subId}`
            : '',
          company_name: subscriptionDetail.company_name,
          mrr: `GBP ${(isMonthly
            ? subscriptionDetail.total
            : subscriptionDetail.total / 12
          ).toFixed(2)}`,
          name: `${userData?.first_name} ${userData?.last_name}${
            subscriptionDetail.chargebee_id
              ? ` (${subscriptionDetail.chargebee_id})`
              : ''
          }`,
          partner: subscriptionDetail.is_partner_customer,
          payment_due: dayjs(
            subscriptionDetail.next_payment_date * 1000,
          ).format('DD MMM, YYYY'),
          plan: `${subscriptionDetail.name} (GBP ${
            subscriptionDetail.price
          } / ${isMonthly ? 'Month' : 'Year'}) ${subscriptionDetail?.addons
            ?.map(
              (v) =>
                `${v.name} (GBP ${v.price} / ${isMonthly ? 'Month' : 'Year'})`,
            )
            .join(', ')}`,

          user_id: data.username,
        }
        getApi().setProperties(_userData)
      }
    } catch (e) {}
  }, [subscriptionDetail, userData, data])
  return <>{children}</>
}

export default CrispProvider
