import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['kycStep'] | null

const initialState = null as InitialStateType

export const kycStepSlice = createSlice({
  initialState,
  name: 'kycStep',
  reducers: {
    setKycStep(state, action: PayloadAction<Req['setKycStep']>) {
      state = action.payload
      return state
    },
  },
})

export const kycStepActions = kycStepSlice.actions
export const useKycStepActions = () => {
  const dispatch = useDispatch()
  const setKycStep = useCallback(
    (payload: Req['setKycStep']) => {
      return dispatch(kycStepActions.setKycStep(payload))
    },
    [dispatch],
  )
  return { setKycStep }
}

const selectKycStep = (state: StoreStateType) => state.kycStep

export const useKycStep = () => {
  const { setKycStep } = useKycStepActions()
  const kycStep = useSelector(selectKycStep)
  return useMemo(() => ({ kycStep, setKycStep }), [setKycStep, kycStep])
}
