import cn from 'classnames'
import { FC } from 'react'
import { Utils } from 'common/utils'
import { parseError } from 'common/utils/parseError'
import Icon, { IconName } from './Icon'

interface Messages {
  /** The error message to be displayed, replaces \n */
  className?: string
  icon?: IconName
  'data-test'?: string
}

//Generic error message
const Message: FC<Messages> = ({
  children,
  className,
  'data-test': dataTest,
  icon,
}) => {
  if (!children) {
    return null
  }

  return (
    <>
      <div data-test={dataTest} className={`alert ${className || ''}`}>
        <div className='flex-row py-2 align-items-center'>
          {icon && <Icon className='me-1' name={icon} />}
          <span data-test='message'>
            {typeof children === 'string'
              ? children.replace(/\n/g, '')
              : 'Error processing request'}
          </span>
        </div>
      </div>
    </>
  )
}

//Default message added alert-danger
export const ErrorMessage: FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={'error'}
    className={cn(className, 'alert-danger text-danger')}
  >
    {parseError(props.children)}
  </Message>
)

// Default message added alert-success
export const SuccessMessage: FC<Messages> = ({ className, ...props }) => (
  <Message
    {...props}
    icon={'check-circle'}
    className={cn(className, 'alert-success text-success')}
  />
)

Message.displayName = 'ErrorMessage'
