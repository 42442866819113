import cn from 'classnames'
import { FC, ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const themeClassNames = {
  darkOutline: 'btn-outline-dark',
  light: 'btn-light',
  lightOutline: 'btn-outline-light',
  lightOutlineThin: 'btn-outline-light-thin',
  pink: 'btn-pink',
  primary: 'btn-primary',
  secondary: 'btn btn-secondary',
  text: 'btn-link',
  white: 'btn-white',
}

export const sizeClassNames = {
  default: '',
  large: 'btn-lg',
  small: 'btn-sm',
  xs: 'btn-xs',
}

export type ButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  iconRight?: IconProp
  iconLeft?: IconProp
  icon?: boolean
  theme?: keyof typeof themeClassNames
  size?: keyof typeof sizeClassNames
}

export const Button: FC<ButtonType> = ({
  children,
  className,
  icon,
  iconLeft,
  iconRight,
  onMouseUp,
  size = 'default',
  theme = 'primary',
  ...rest
}) => {
  const iconColor = theme !== 'primary' ? '#1D1D1F' : '#fff'
  return (
    <button
      type='button'
      {...rest}
      onMouseUp={onMouseUp}
      className={cn(
        { btn: true, 'btn-icon': !!icon },
        themeClassNames[theme],
        sizeClassNames[size],
        className,
      )}
    >
      <div
        className={classNames(
          (iconLeft || iconRight) && 'flex-row align-items-center gap-1',
        )}
      >
        {!!iconLeft && (
          <FontAwesomeIcon
            icon={iconLeft}
            color={iconColor}
            height={20}
            width={20}
          />
        )}
        <div className='flex-fill text-center'>{children}</div>
        {!!iconRight && (
          <span className='ms-2'>
            <FontAwesomeIcon
              icon={iconRight}
              color={iconColor}
              height={20}
              width={20}
            />
          </span>
        )}
      </div>
    </button>
  )
}

Button.displayName = 'Button'
export default Button
