import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['userData'] | null

const initialState = null as InitialStateType

export const userDataSlice = createSlice({
  initialState,
  name: 'userData',
  reducers: {
    setUserData(state, action: PayloadAction<Req['setUserData']>) {
      state = action.payload
      return state
    },
  },
})

export const userDataActions = userDataSlice.actions
export const useUserDataActions = () => {
  const dispatch = useDispatch()
  const setUserData = useCallback(
    (payload: Req['setUserData']) => {
      return dispatch(userDataActions.setUserData(payload))
    },
    [dispatch],
  )
  return { setUserData }
}

const selectUserData = (state: StoreStateType) => state.userData

export const useUserData = () => {
  const { setUserData } = useUserDataActions()
  const userData = useSelector(selectUserData)
  return useMemo(() => ({ setUserData, userData }), [setUserData, userData])
}
