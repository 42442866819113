import React, { FC, useState } from 'react'

import Icon from './Icon'
import Button from './base/forms/Button'
import ModalDefault from './ModalDefault'
import Topup from './Topup'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { Format } from 'common/utils'
import { useGetManageSubscriptionQuery } from 'common/services/useManageSubscription'
import clearCBCookies from 'project/clearCBCookies'

type TopupSelectType = {}

const TopupSelect: FC<TopupSelectType> = ({}) => {
  const [hovered, setHovered] = useState(false)
  const [showTopup, setShowTopup] = useState(false)
  const { hasPermission, subscriptionDetail } = useDefaultSubscription()
  const canTopup = hasPermission('TOPUP')
  const isPartnerCustomer = subscriptionDetail?.is_partner_customer
  const { data: manageSubscriptionUrl, refetch } =
    useGetManageSubscriptionQuery(
      {
        id: `${subscriptionDetail?.company_id}`,
      },
      {
        skip: !subscriptionDetail?.company_id,
      },
    )
  const handleManageSubscription = () => {
    refetch()
    clearCBCookies()
    window.open(manageSubscriptionUrl?.access_url || '', '_blank')
  }

  return (
    <div className='nav-item dropdown'>
      <a
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`nav-link flex-row align-items-center dropdown-toggle border-rounded-full px-2 px-md-6 btn-light`}
        href='#'
        role='button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <span className={`mx-2 flex-fill text-truncate text-bold`}>
          {Format.money(subscriptionDetail?.company?.account_balance || 0)}
        </span>
        <span className='me-3'>
          <span className='navbar-icon fa fa-chevron-down' />
        </span>
      </a>
      <div className='dropdown-menu bg-secondaryDark border-0 topup-dropdown'>
        <div className='d-flex justify-content-end p-5'>
          <Icon fill='white' className='cursor-pointer' name='times' />
        </div>
        <div className='mx-5 mb-4'>
          {hasPermission('TOPUP') ? (
            <Button
              onClick={() => setShowTopup(true)}
              theme='lightOutline'
              className='w-100'
            >
              Add Funds
            </Button>
          ) : (
            <div className='text-center text-white'>
              You do not have permissions to add funds to the account.
            </div>
          )}

          {!!isPartnerCustomer && !!manageSubscriptionUrl?.access_url && (
            <Button
              onClick={handleManageSubscription}
              theme='lightOutline'
              className='mt-2 w-100'
            >
              Manage Payment Method
            </Button>
          )}
        </div>
      </div>
      <ModalDefault
        isOpen={showTopup}
        onDismiss={() => setShowTopup(false)}
        toggle={() => setShowTopup(!showTopup)}
      >
        <Topup
          closeModal={() => setShowTopup(false)}
          onComplete={() => setShowTopup(false)}
        />
      </ModalDefault>
    </div>
  )
}

export default TopupSelect
