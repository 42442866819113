import cookies from 'js-cookie'

export default function () {
  try {
    cookies.remove('cbt', { domain: 'checkout.hoxtonmix.com' })
    cookies.remove('ch', { domain: 'checkout.hoxtonmix.com' })
    cookies.remove('authType', { domain: 'checkout.hoxtonmix.com' })
    cookies.remove('ct', { domain: 'checkout.hoxtonmix.com' })
    cookies.remove('debug', { domain: 'checkout.hoxtonmix.com' })
  } catch (e) {}
}
