import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'

const defaultContent = {
  address: 'Address',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  addressLine3: 'Address line 3',
  billingAddress: 'Billing address',
  city: 'City',
  country: 'Country',
  enterAddressOrPostcode: 'Postcode',
  enterManually: 'Enter address manually',
  postcode: 'Postcode',
  sameAsShippingAddress: 'Same as shipping address',
  searchAddress: 'Search for your address',
  selectAddressThatMatchesPaymentMethod:
    'Select the address that matches your card or payment method.',
  shippingAddress: 'Shipping address',
  startTypingToSearch: 'Start typing your address to search...',
  useADifferentBillingAddress: 'Use a different billing address',
}
export const addressStrings = getApi().makeStrings({
  en: defaultContent,
  es: blobbyStrings(defaultContent),
})
