import { getPaymentStatusText, getPaymentTypeText } from 'common/utils/payments'
import { useGetPaymentSourcesQuery } from 'common/services/usePaymentSource'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { Constants, Format } from 'common/utils'
import { PaymentSourceStatus } from 'common/types/responses'
import { Project } from 'common/project'

export const usePaymentWarning = () => {
  const { defaultSubscriptionId, hasPermission, subscriptionDetail } =
    useDefaultSubscription()
  const useCompanyId = subscriptionDetail?.is_partner_customer
  const { data } = useGetPaymentSourcesQuery(
    {
      company_id: useCompanyId ? `${defaultSubscriptionId}` : undefined,
      subscription_id: useCompanyId ? undefined : `${defaultSubscriptionId}`,
    },
    { skip: !hasPermission('MANAGE_BILLING') },
  )

  let paymentWarning = ''
  const status =
    // @ts-ignore
    (Constants.simulate.payment_state as PaymentSourceStatus) ||
    (data && data.status)
  if (data) {
    const showPaymentWarning =
      status === 'expired' || status === 'invalid' || status === 'expiring'
    paymentWarning = showPaymentWarning
      ? `${getPaymentTypeText(data.type)} ${getPaymentStatusText(status)}`
      : ''
  }
  return { paymentWarning, status }
}

export const prettyCardStatus = (status: PaymentSourceStatus) => {
  return Format.enumeration.get(status)
}

export const longCardStatus = (status: PaymentSourceStatus) => {
  let description = ''
  switch (status) {
    case 'invalid':
      description = 'invalid'
      break
    case 'expiring':
      description = 'expiring soon'
      break
    default:
      return ''
  }
  return `Your payment method is ${description}, update your details to retain access to your ${Project.siteName} account`
}
